$('.navbar li.dropdown.custom a').on('click', function (event) {
    $(this).parent().toggleClass('open');
});

$('.navbar li.dropdown.custom').on('click', function(event){
    $('.navbar li.dropdown.custom.open').removeClass('open'); 
    if (!$(this).hasClass('open')) { 
        $(this).addClass("open");
    }
});

$('.navbar .close-submenu-button').on('touchend', function (event) {
    event.preventDefault();
     $('.navbar li.dropdown.custom').removeClass('open');
});
$('body').on('click', function (e) {
    if (!$('li.dropdown.custom').is(e.target) 
        && $('li.dropdown.custom').has(e.target).length === 0 
        && $('.open').has(e.target).length === 0
    ) {
        $('.navbar li.dropdown.custom').removeClass('open');
    }
});