(jQuery)(document).ready(function($){


	$(".filter-certificado .tile-content").click(function(event) {
		event.preventDefault();
		$(this).parent().parent().parent().find("h2").hide();
		$(".faq-filter-tile.active").removeClass("active");
		$(this).parent().addClass("active");
		show($(".cat-certificado"));
		hide($(".faq-accordion-item").not(".cat-certificado"));
	})
	$(".filter-iniciar .tile-content").click(function(event) {
		event.preventDefault();
		$(this).parent().parent().parent().find("h2").hide();		
		$(".faq-filter-tile.active").removeClass("active");
		$(this).parent().addClass("active");
		show($(".cat-iniciar"));
		hide($(".faq-accordion-item").not(".cat-iniciar"));
	})
	$(".filter-firmaelectronica .tile-content").click(function(event) {
		event.preventDefault();
		$(this).parent().parent().parent().find("h2").hide();		
		$(".faq-filter-tile.active").removeClass("active");
		$(this).parent().addClass("active");
		show($(".cat-firmaelectronica"));
		hide($(".faq-accordion-item").not(".cat-firmaelectronica"));
	})

	$(".faq-filter-tile .disable-wrapper").click(function(event) {
		event.preventDefault();
		event.stopPropagation();
		$(this).parent().parent().parent().find("h2").show();
		$(".faq-filter-tile.active").removeClass("active");
		show($(".faq-accordion-item"));
	})

	function show(element) {
		element.css("display", "block");
		element.animate({
			maxHeight: "1000px",
			opacity: 1
		}, 500,"linear", function() {
			element.css("display", "block");
		});
	}

	function hide(element) {
		element.animate({
			maxHeight: "0px",
			opacity: 0
		}, 500,"linear", function() {
			element.css("display", "none");
		});
	}
});