$(".custom-checkbox.not-terms").click(function() {
  $(this).toggleClass('selected');

  var val = $(this).hasClass('selected') == true;

  console.log(val, 'checkbox value');

  $(this).siblings('input').val(val);
});


$(".custom-checkbox.terms").click(function() {
    $(this).toggleClass('selected');
    $(this).siblings('input').val($(this).siblings('input').val() !== "true").attr("checked", true);
});
